<template>
  <b-container fluid>
    <div class="row p-2 bg-white text-dark">
      <div class="col-sm-3 text-center">
        <router-link :to="{ name: 'app.company' }" v-if="device !== 'ios'"> {{ $t('pay.back_to_company') }}</router-link>
        <span v-if="device === 'ios'" @click="refreshContent">{{ $t('pay.back_to_company') }}</span>
        <img :src="require('../../assets/images/booking/booking-01.png')" width="100%"/>
      </div>
      <div class="col-sm-6">
        <h2>{{ planName }}</h2>
        <h4 style="margin-bottom: 20px;">{{ $t('pay.billing_cycle') }}</h4>
        <b-form-group>
          <b-form-radio v-model="billingcycle" style="font-size: 16px;" value="monthly" @change="setThePeriod('monthly')">
            {{ billingMonthly }}
            <span v-if="device !== 'ios'">{{ $t('pay.monthly_payment_text') }}</span>
            <span v-if="device === 'ios'">{{ $t('pay.monthly_payment_text_ios') }}</span>
          </b-form-radio>
          <b-form-radio v-model="billingcycle" style="font-size: 16px;" value="yearly" @change="setThePeriod('yearly')">
            {{ billingYearly }}
            <span v-if="device !== 'ios'">{{ $t('pay.yearly_payment_text') }}</span>
            <span v-if="device === 'ios'">{{ $t('pay.yearly_payment_text_ios') }}</span>
          </b-form-radio>
        </b-form-group>

        <div class="voucherAdded" v-if="device !== 'ios' && voucherAdded">{{ $t('pay.voucher') }} {{ voucherText }} <span class="removeVoucher" @click="removeVoucher">{{ $t('pay.delete_voucher') }}</span></div>
        <input class="voucherText" v-model="voucherText" type="text" :placeholder="$t('pay.add_voucher')" v-if="device !== 'ios' && voucherAdd && !voucherAdded" />
        <b-button class="voucherAdd" v-if="device !== 'ios' && voucherAdd && !voucherAdded" @click="setVoucher">{{ $t('pay.add_voucher') }}</b-button>
        <b-button class="voucherButton" v-if="device !== 'ios' && !voucherAdd && !voucherAdded" @click="addVoucher">{{ $t('pay.got_voucher') }}</b-button>
        <div class="voucherError" v-if="device !== 'ios' && voucherError && !voucherAdded">{{ $t('pay.invalid_voucher') }}</div>
        <div style="margin-top:30px" ref="paypalButtons" id="paypalButtons" v-if="device !== 'ios'"></div>
        <div style="margin-top:30px" v-if="device === 'ios'">
          <b-button variant="success" @click="payInAppPurchase">{{ $t('pay.upgrade_plan') }}</b-button>
        </div>
      </div>
      <div class="col-sm-12 text-right mt-4">
        {{ $t('pay.have_problems') }} <a href="mailto:office@12booky.com">office@12booky.com</a>
      </div>
    </div>
    <div class="isLoading" v-if="isLoading"><div class="loader"></div></div>
    <div class="paymentSuccess" v-if="paymentSuccess">
      <div class="wrapper">
        {{ $t('pay.success') }}
      </div>
    </div>
  </b-container>
</template>
<script>
import Axios from 'axios'
import { API } from '@/config/api.config'
import { Capacitor } from '@capacitor/core'

export default {
  name: 'app',
  data () {
    return {
      device: Capacitor.getPlatform() || 'web',
      apiToken: localStorage.getItem('api_token') || '',
      billing: 'monthly',
      paymenttype: 'creditcard',
      payment: '',
      paymentId: '',
      planId: this.$route.params.id,
      planName: '',
      billingMonthly: 1,
      billingYearly: 1,
      voucherAdd: false,
      voucherAdded: false,
      voucherText: '',
      voucherError: false,
      billingcycle: 'monthly',
      paymentSuccess: false,
      isLoading: false
    }
  },
  mounted () {
    if (this.device === 'ios') {
      this.isLoading = true
      this.initializeIAP()
      window.store.ready(() => {
        let planMonthly = ''
        let planYearly = ''
        if (this.planId === '2') {
          planMonthly = window.window.store.get('in_mo')
          planYearly = window.window.store.get('in_ye')
        } else if (this.planId === '3') {
          planMonthly = window.window.store.get('bu_mo')
          planYearly = window.window.store.get('bu_ye')
        } else if (this.planId === '4') {
          planMonthly = window.window.store.get('en_mo')
          planYearly = window.window.store.get('en_ye')
        }
        this.billingMonthly = planMonthly.price
        this.billingYearly = planYearly.price
        this.isLoading = false
      })
    } else {
      const script = document.createElement('script')
      script.src = 'https://www.paypal.com/sdk/js?client-id=Ae6Y3mH35kH1wzmFmLZMbslk_nyXwn6ZS2MKlS75QLpBw21q1CdI78QQHKJMasvMkROVRnfiw8vwb_U5&vault=true&intent=subscription'
      // script.src = 'https://www.paypal.com/sdk/js?client-id=AWEjygg_4H72oCy7nsQwQ1jpqdOX_nlhzMuY3wsuSbq5CCBFrj9hslSH93XWui33ASu8nN7CrIyEtKk4&vault=true&intent=subscription'
      // script.src = 'https://www.paypal.com/sdk/js?client-id=Ae6Y3mH35kH1wzmFmLZMbslk_nyXwn6ZS2MKlS75QLpBw21q1CdI78QQHKJMasvMkROVRnfiw8vwb_U5&currency=EUR'
      // script.src = 'https://www.paypal.com/sdk/js?client-id=AWEjygg_4H72oCy7nsQwQ1jpqdOX_nlhzMuY3wsuSbq5CCBFrj9hslSH93XWui33ASu8nN7CrIyEtKk4&currency=EUR'
      // script.addEventListener('load', this.setLoaded)
      document.head.appendChild(script)
      this.getPlanDetails()
    }
  },
  methods: {
    refreshContent () {
      window.location = '/app/company'
    },
    payInAppPurchase () {
      let planName = ''
      if (this.planId === '2') {
        planName = 'in'
      } else if (this.planId === '3') {
        planName = 'bu'
      } else if (this.planId === '4') {
        planName = 'en'
      }
      const thePlan = planName + (this.billingcycle === 'monthly' ? '_mo' : '_ye')
      console.log(thePlan)
      return window.order(thePlan)
    },
    initializeIAP () {
      document.addEventListener('deviceready', () => {
        const store = window.store
        const products = [{
          id: 'in_mo',
          type: store.NON_RENEWING_SUBSCRIPTION
        },
        {
          id: 'in_ye',
          type: store.NON_RENEWING_SUBSCRIPTION
        },
        {
          id: 'bu_mo',
          type: store.NON_RENEWING_SUBSCRIPTION
        },
        {
          id: 'bu_ye',
          type: store.NON_RENEWING_SUBSCRIPTION
        },
        {
          id: 'en_mo',
          type: store.NON_RENEWING_SUBSCRIPTION
        },
        {
          id: 'en_ye',
          type: store.NON_RENEWING_SUBSCRIPTION
        }
        ]
        store.verbosity = store.DEBUG
        store.register(products)
        store.error((error) => {
          console.log('EROARE ' + error.code + '-' + error.message)
        })
        store.when()
          .approved(p => p.verify())
          .verified(p => {
            this.isLoading = true
            const postData = {
              plan: this.planId,
              monthly: this.billingcycle,
              voucher: '',
              details: p.transaction,
              currency: p.currency,
              price: p.price,
              translations: {
                sale_invoice: this.$t('pay.sale_invoice'),
                seller_name: this.$t('pay.seller_name'),
                purchaser_name: this.$t('pay.purchaser_name'),
                payment_paid: this.$t('pay.payment_paid'),
                billing_from: this.$t('pay.billing_from'),
                billing_to: this.$t('pay.billing_to')
              }
            }
            Axios.defaults.headers.common.Authorization = 'bearer ' + this.getApiToken()
            Axios.post(API.API_SET_SUBSCRIPTION, postData)
              .then((response) => {
                if (response.data.status === 'success') {
                  this.isLoading = false
                  this.paymentSuccess = true
                  setTimeout(function () {
                    window.location = '/app/company'
                    this.isLoading = false
                  }, 5000)
                }
              })
              .catch((error) => {
                console.error(error.response)
                if (error.response && error.response.status === 401) {
                  this.$router.push({ name: 'auth1.login' })
                  this.isLoading = false
                }
              })
            this.isLoading = false
            p.finish()
          })
          .owned(p => console.log(`you now own ${p.alias}`))
        store.refresh()
        window.order = (productID) => {
          store.order(productID)
        }
      })
    },
    getPlanDetails () {
      const availablePlans = ['2', '3', '4']
      if (!availablePlans.includes(this.planId)) {
        this.$router.push('/app/dashboard')
      }
      const dataPost = {
        plan: this.planId
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.getApiToken()
      Axios.post(API.API_GET_PLAN_DETAILS, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.planName = response.data.plan.name
            this.billingMonthly = response.data.plan.monthly
            this.billingYearly = response.data.plan.yearly
            this.setLoaded()
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    addVoucher () {
      this.voucherAdd = true
    },
    setVoucher () {
      const dataPost = {
        plan: this.planId,
        voucher: this.voucherText
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.getApiToken()
      Axios.post(API.API_SET_VOUCHER, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.billingMonthly = response.data.plan.monthly
            this.billingYearly = response.data.plan.yearly
            this.voucherAdded = true
            this.setLoaded()
          } else if (response.data.status === 'fail') {
            this.voucherError = true
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    removeVoucher () {
      const dataPost = {
        plan: this.planId
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.getApiToken()
      Axios.post(API.API_REMOVE_VOUCHER, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.billingMonthly = response.data.plan.monthly
            this.billingYearly = response.data.plan.yearly
            this.voucherAdd = false
            this.voucherText = ''
            this.voucherAdded = false
            this.voucherError = false
            this.setLoaded()
          } else if (response.data.status === 'fail') {
            this.voucherError = true
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    setLoaded () {
      const thisOne = this
      const isMonthly = this.billingcycle
      // const description = '12 Booky - ' + isMonthly + ' ' + this.$t('pay.payment')
      // const theValue = this.billingcycle === 'monthly' ? this.billingMonthly : this.billingYearly
      let planId = 'P-20326754KT4082004MQ2ALJA'
      if (this.billingcycle === 'monthly') {
        if (this.planId === '3') {
          planId = 'P-9D9270448E4962053MQ2PM5Y'
        } else if (this.planId === '4') {
          planId = 'P-2NM42576H5946724GMQ2PNTA'
        }
      } else {
        if (this.planId === '2') {
          planId = 'P-4NU3142354984431XMQ2PXTY'
        } else if (this.planId === '3') {
          planId = 'P-34J87132VY5511235MQ2PX6Y'
        } else if (this.planId === '4') {
          planId = 'P-2S292688KB7355059MQ2PYQY'
        }
      }

      document.getElementById('paypalButtons').innerHTML = ''
      window.paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe'
        },
        createSubscription: function (data, actions) {
          return actions.subscription.create({
            /* Creates the subscription */
            plan_id: planId
          })
        },
        onApprove: function (data, actions) {
          return actions.order.capture().then(function (details) {
            if (details.status === 'COMPLETED') {
              thisOne.isLoading = true
              const postData = {
                plan: thisOne.planId,
                monthly: isMonthly,
                voucher: thisOne.voucherText,
                details: details,
                translations: {
                  sale_invoice: thisOne.$t('pay.sale_invoice'),
                  seller_name: thisOne.$t('pay.seller_name'),
                  purchaser_name: thisOne.$t('pay.purchaser_name'),
                  payment_paid: thisOne.$t('pay.payment_paid'),
                  billing_from: thisOne.$t('pay.billing_from'),
                  billing_to: thisOne.$t('pay.billing_to')
                }
              }
              Axios.defaults.headers.common.Authorization = 'bearer ' + thisOne.getApiToken()
              Axios.post(API.API_SET_SUBSCRIPTION, postData)
                .then((response) => {
                  if (response.data.status === 'success') {
                    thisOne.isLoading = false
                    thisOne.paymentSuccess = true
                    setTimeout(function () {
                      window.location = '/app/company'
                    }, 5000)
                  }
                })
                .catch((error) => {
                  console.error(error.response)
                  if (error.response && error.response.status === 401) {
                    this.$router.push({ name: 'auth1.login' })
                  }
                })
            } else {
              console.warn('error')
            }
          })
        },
        onError: (error) => {
          console.warn(error)
        }
      }).render(this.$refs.paypalButtons)
    },
    getApiToken () {
      return this.apiToken
    },
    setThePeriod (type) {
      this.billingcycle = type
      this.setLoaded()
    }
  },
  computed: {
  }
}
</script>
<style>
.voucherText{
  border: 1px solid grey;
  border-radius: 4px;
  padding-left: 5px;
  margin-right: 3px !important;
}
.voucherButton{
  background: transparent !important;
  color: #4fa0f7 !important;
  border: 0px !important;
  text-decoration: underline;
  display: none !important;
}
.voucherAdded{
  background: #429e7b;
  color: white;
  border: 1px solid green;
  border-radius: 2px;
  padding-left: 5px;
  position: relative;
}
.removeVoucher{
  position: absolute;
  right: 5px;
  font-size: 10px;
  color: yellow;
  margin-top: 3px;
  cursor: pointer;
}
.voucherError{
  color: red;
  font-size: 11px;
}
body, html{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: #fff !important;
  top: 0;
  bottom: 0;
  padding-top: 50px !important;
}
.paymentSuccess{
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.58);
  top: 0;
  left: 0;
  z-index: 999;
  display: table;
}
.paymentSuccess .wrapper{
  width: 50%;
  height:50%;
  text-align: center;
  color: white;
  background: #28a745c7;
  display: table-cell;
  vertical-align: middle;
}
.isLoading{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  background: #00000078;
  width: 100%;
  height: 100%;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #19bdb4;
  width: 120px !important;
  height: 120px !important;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
